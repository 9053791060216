import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ProcessedProduct } from '../../pages/Menu/Products/types/menu.types';

interface Category {
  original: string;
  edited: string;
  created?: boolean;
  id?: string;
}

export interface MenuUploaderState {
  categories: Category[];
  products: ProcessedProduct[];
  currentCategoryIndex: number;
  processStep: 'categories' | 'products' | 'completed';
  isFetching: boolean;
}

const initialState: MenuUploaderState = {
  categories: [],
  products: [],
  currentCategoryIndex: -1,
  processStep: 'categories',
  isFetching: false,
};

export const menuUploaderSlice = createSlice({
  name: 'menuUploader',
  initialState,
  reducers: {
    setCategories: (state, action: PayloadAction<Category[]>) => {
      state.categories = action.payload;
    },
    setProducts: (state, action: PayloadAction<ProcessedProduct[]>) => {
      state.products = action.payload;
    },
    updateCategory: (state, action: PayloadAction<{ index: number; category: Category }>) => {
      const { index, category } = action.payload;
      if (state.categories[index]) {
        state.categories[index] = {
          ...state.categories[index],
          ...category,
          created: category.created ?? state.categories[index].created,
        };
      }
    },
    updateProduct: (
      state,
      action: PayloadAction<{
        index: number;
        field: keyof ProcessedProduct;
        value: ProcessedProduct[keyof ProcessedProduct];
      }>,
    ) => {
      const { index, field, value } = action.payload;
      if (state.products[index]) {
        state.products[index] = {
          ...state.products[index],
          [field]: field === 'precio' ? value || 0 : value || '',
        };
      }
    },
    setCurrentCategoryIndex: (state, action: PayloadAction<number>) => {
      state.currentCategoryIndex = action.payload;
    },
    setProcessStep: (state, action: PayloadAction<'categories' | 'products' | 'completed'>) => {
      state.processStep = action.payload;
    },
    setIsFetching: (state, action: PayloadAction<boolean>) => {
      state.isFetching = action.payload;
    },
    reset: () => initialState,
  },
});

export const {
  setCategories,
  setProducts,
  updateCategory,
  updateProduct,
  setCurrentCategoryIndex,
  setProcessStep,
  setIsFetching,
  reset,
} = menuUploaderSlice.actions;

export default menuUploaderSlice.reducer;
