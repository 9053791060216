import { ModuleKey, ModulePermissions, PermissionsObj } from '@interfaces/Permissions';
import { useSubscription } from '@pages/Subscriptionsv2/hooks/useSubscriptions';
import { shouldShowSubscriptionModal } from '@pages/Subscriptionsv2/utils/getShowSubscriptionModal';
import { User } from '@pages/Users/types';
import { MODULES_PATH } from '@utils/constants';

interface Modules {
  hasModule: (module: ModuleKey) => boolean | null;
  modulePermissions: (module: ModuleKey) => PermissionsObj;
  getRootPath: () => string;
}

export const getLabelTranslation = (key: ModulePermissions) => {
  switch (key) {
    case 'list':
      return 'Visualizar';
    case 'create':
      return 'Crear';
    case 'order':
      return 'Ordenar';
    case 'edit':
      return 'Editar';
    case 'delete':
      return 'Eliminar';
    default:
      return '';
  }
};

export const getModulePermissionOptions = (permissions: PermissionsObj) => {
  const moduleOptions: Array<{ value: string; label: string }> = [];
  (Object.keys(permissions) as (keyof typeof permissions)[]).map((key) => {
    const label = getLabelTranslation(key);
    if (permissions[key] && label) {
      moduleOptions.push({
        value: key,
        label: label,
      });
    }
  });
  return moduleOptions;
};

export const getUserModulePermissions = (moduleName: ModuleKey, userModules: User['modulos']) => {
  return (
    userModules
      .find((mod) => mod.id === moduleName)
      ?.permisos.map((perm) => ({
        value: perm,
        label: getLabelTranslation(perm),
      })) || []
  );
};

export const haveOnePermission = (permission: ModulePermissions, permissions: PermissionsObj) => {
  return (
    (Object.keys(permissions) as (keyof typeof permissions)[]).some((p) => p === permission) &&
    (Object.keys(permissions) as (keyof typeof permissions)[]).length === 1
  );
};

const useModules = (): Modules => {
  const { planId, modulesAndPermissions: permissions } = useSubscription();

  return {
    hasModule: (module: ModuleKey) => {
      return permissions.length ? permissions.some((m) => m.id === module) : null;
    },
    modulePermissions: (module: ModuleKey) => {
      const modulePermissions = permissions.find((m) => m.id === module)?.permisos;
      const permissionsObj: PermissionsObj = {};
      if (modulePermissions) modulePermissions.forEach((permissionKey) => (permissionsObj[permissionKey] = true));
      return permissionsObj;
    },
    getRootPath: () => {
      if (!permissions || !permissions.length) return '/';

      const shouldShowModal = shouldShowSubscriptionModal(planId);
      if (shouldShowModal) return shouldShowModal;

      const entries = Object.entries(MODULES_PATH);
      const firstModule = entries.find((entry) => permissions.some((m) => m.id === entry[0]));
      return firstModule ? firstModule[1] : '/';
    },
  };
};

export default useModules;
