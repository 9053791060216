import toast from 'react-hot-toast';
import ButtonAction from '@components/interface/ButtonAction';

type ToastValidatorProps = { message: string; onValidate: () => void; onCancel?: () => void };
const toastValidator = ({ message, onValidate, onCancel }: ToastValidatorProps) => {
  toast((t) => {
    if (t.style) {
      t.style.backgroundColor = '#d54c4c';
    }
    t.duration = 10000;
    return (
      <div>
        <span>{message}</span>
        <div className="flex w-full justify-center">
          <ButtonAction
            colorBg="color-warning"
            colorTxt="color-white"
            textButton="SI"
            onClick={async () => {
              await toast.dismiss(t.id);
              setTimeout(() => {
                onValidate();
              }, 100);
            }}
          />
          <ButtonAction
            colorBg="color-warning"
            colorTxt="color-white"
            hasBorder
            textButton="NO"
            onClick={async () => {
              await toast.dismiss(t.id);
              if (onCancel) {
                setTimeout(() => {
                  onCancel();
                }, 100);
              }
            }}
          />
        </div>
      </div>
    );
  });
};

export default toastValidator;
